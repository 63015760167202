.HeaderContainer{
    padding: 25px 22px !important;
    box-shadow: 0px 6px 13px 0px #7B7B7B0D,
    0px 23px 23px 0px #7B7B7B0A,
    0px 52px 31px 0px #7B7B7B08,
    0px 92px 37px 0px #7B7B7B03,
    0px 143px 40px 0px #7B7B7B00 !important;

}
.logoTextStyle,.Aboutustext,.contactUsTitle {
    font-family: "Satisfy" !important;
    color: #986633 !important;
    font-size: 32px !important;
    font-weight: 400 !important;
    text-wrap: nowrap !important;
    text-align: center !important;
}
.HeaderText{
    font-family: "Cormorant Garamond" !important;
    color: #000000 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21.8px !important;
    text-wrap: nowrap !important;
}
.leftContent{
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), 
        url('../../assets/leftContent.jpg');
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position:  relative !important;
    /* height: 500px !important; */
    width: 40% !important;  
    aspect-ratio: 2 / 1.8
}
.leftContent::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(245, 207, 142, 0.5);
    z-index: 1;
}
.innerContent {
    z-index: 2 !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content !important; 
    width: fit-content !important;   
}

.rightContent{
    /* background-color: green !important; */
    width: 60% !important;
}
.quotestext{
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #000000 !important;
}
.orderNowButton,.AboutUsShortDiscription{
    font-family: "Poppins" !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    background: #986633 !important;
    text-transform: none !important;
}
.mainCoursel{
    width: 100%;
    height: 100% !important; 
    overflow: hidden !important;
    position: relative !important;
    
}
.mainCoursel img {
    transition: opacity 1s ease-in-out !important;
  }
  .carousel-image.fade-in {
    opacity: 1;
  }
  
  .carousel-image.fade-out {
    opacity: 0;
  }
.Aboutustext{
    font-size: 36px !important;
    font-weight: 400 !important;
    line-height: 51px !important;
    text-align: left !important;
}
.AboutUsShortDiscription,.AboutUsDiscription{
    color: #000000 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    background-color: unset !important;
}
.AboutUsDiscription,.courselCardName,.contactusDiscription,.ContactCardText{
    font-size: 16px !important;
    line-height: 36px !important;
    font-weight: 400 !important;
}
.aboutUsImage{
    width: 100% !important;
    height: auto !important;
    /* aspect-ratio: 2 / 2 !important; */
}
.courselCardName{
    color: #000000 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-wrap: nowrap !important;
}
.contactUsTitle{
    font-size: 36px !important;
    font-weight: 400 !important;
    line-height: 51px !important;
    color:#F5CF8E !important;
}
.FooterContainer {
    position: relative;
    background-image: url('../../assets/footerImage.jpg') !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    overflow: hidden;
    padding-top: 50px !important ;
}

.FooterContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); 
    backdrop-filter: blur(1px); 
    z-index: 1; 
}

.FooterContainer > * {
    position: relative;
    z-index: 2;
}
.contactusDiscription{
    color: #FFFFFF !important;
    text-wrap: wrap !important;
    text-align: center !important;
}
.footerContactCard{
    position: relative !important;
    width: 300px !important;
    background-color: #FFFFFF !important;
    padding-top: 32px  !important;
    padding-bottom: 32px !important;
    overflow: visible !important;
    cursor: pointer !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;     
}
.ContactIconContainer{
    content: ' ';
    position: absolute !important;
    top: -20px !important;
    bottom: 0 !important;
    left: 40% !important;
    right: 0 !important;
    z-index: 52 !important;
}
.ContactCardText{
    color: #986633 !important;
    font-weight: 600 !important;
}
.footerTermAndCondition{
    border-top: 1px solid #986633 !important;
    padding: 13px !important;
}
.FooterCopyRightText{
    font-family: 'Poppins' !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: right !important;
}
.FooterLogoText{
    font-family: 'Satisfy' !important;
    color: #FFFFFF !important;
    font-size: 32px;
    font-weight: 400;
    line-height: 46.09px;
    text-align: center;
}
.CarftedByText{
    font-family: 'Poppins' !important;
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
}
@media (max-width: 992px) {
    
    .leftContent {
        width: 100% !important; 
        /* height: unset !important;  */
        padding: 80px 0 !important;
        aspect-ratio: 2 / 1
    }
    .rightContent{
        /* background-color: green !important; */
        width: 100% !important;
        aspect-ratio: 2 / 1
    }
    .aboutUsImage{
        width: 100% !important;
        aspect-ratio: 2 / 2 !important;
    }
}
@media (max-width: 900px) {
    
    .aboutUsImage{
        width: 100% !important;
        aspect-ratio: 2 / 2 !important;
    }
}

@media (max-width: 768px ){
    .leftContent {
        width: 100% !important; 
        height: 400px !important; 
        padding: 80px 0 !important;
        aspect-ratio: unset
    }
    .cardContainer {
        width: 165px !important;
        height: 198px !important;
        position: relative;
        background: #eee;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}


.cardContainer {
    width: 250px;
    height: 300px;
    position: relative;
    background: #eee;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentOverlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    url('../../assets/leftContent.jpg');
    
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    transition: transform 0.5s ease-in-out;
}

.contentOverlay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(245, 207, 142, 0.5);
    z-index: 1;
}

.title {
    color: #000000;
    font-size: 1.2em;
    margin-bottom: 10px;
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);
}
.cardContainer:hover .title {
    padding-top: 5px !important;
} 

/* Button styling */
.button {
    position: absolute;
    bottom: 10px; 
    opacity: 0;
    visibility: hidden; 
    background: #986633;
    border: none !important;
    border-radius: 4px !important ;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transform: translateY(20px);
    z-index: 9999 !important; 
}


.cardContainer:hover .button {
    position: relative !important;
    bottom: 10px; 
    opacity: 1; 
    visibility: visible !important;
    pointer-events: visible !important;
    transform: translateY(0); 
}

.cardContainer:hover .title {
    transform: translateY(-10px);
}
