.body{
  padding:0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  background-color: transparent !important;
  /* width: 100vw !important; */
  overflow-x: hidden !important;
}
html {
  scroll-behavior: smooth;
}
